.pass {
	color: green;
}

.fail {
	color: red;
	font-weight: 600;
}

.inProgress {
	font-style: italic;
}

tr {
	line-height: 24px;
}
