.App {
	text-align: left;
	max-width: 100%;
	margin: 27px;
	font-family: 'Source Sans Pro', sans-serif;
}

@media only screen and (min-width: 800px) {
	.App {
		text-align: left;
		max-width: 800px;
		margin: 27px auto;
		font-family: 'Source Sans Pro', sans-serif;
	}
}

.line {
	width: 100%;
	border-bottom: 1px solid black;
}

* {
	color: rgb(64, 64, 64);
}

.focus {
	color: rgb(0, 0, 0);
	font-weight: 600;
}

.disclaimer {
	font-size: small;
}
